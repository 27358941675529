@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "SanPlomb";
    src: url("/src/assets/fonts/SansPlomb_TRIAL-98.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SanPlomb-Super";
    src: url("/src/assets/fonts/SansPlomb_TRIAL-Super.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GeneralSans";
    src: url("/src/assets/fonts/GeneralSans-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GeneralSans-Medium";
    src: url("/src/assets/fonts/GeneralSans-Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "GeneralSans-Bold";
    src: url("/src/assets/fonts/GeneralSans-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
}

.input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-datepicker {
    display: inline-block;
    position: relative;
    background-color:rgba(255, 255, 255, 0.3) !important;
}

.react-datepicker__header {
    background-color:  transparent !important;
    border-bottom: 1px solid white !important;
    color: white !important;
}
.react-datepicker__current-month {
    color: white !important;
}
.react-datepicker__day-name {
    color: white !important;
    font-size: larger;

    @media screen and (max-width: 1440px) {
        font-size: smaller;
    }
}

.react-datepicker__day {
    color: white !important;
    font-size: larger;

    @media screen and (max-width: 1440px) {
        font-size: smaller;
    }
}
.react-datepicker__day:hover {
    background-color: #29305f !important;
}
.react-datepicker__day--in-selecting-range {
    background-color: #29305f !important;
}
.react-datepicker__day--in-selecting-range-start {
    background-color: #29305f !important;
}
.react-datepicker__day--in-selecting-range-end {
    background-color: #29305f !important;
}
.react-datepicker__day--in-range {
    background-color: #29305f !important;
}
.react-datepicker__day--disabled {
    color: #bebebe !important;
}
.react-datepicker__day--disabled:hover {
    background-color: transparent !important;
}
.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
}
